<template>
  <div class="treasury-cash-boxes-list fixed-draggable-dynamic-table-wrapper-height top-zero-radius">
    <draggable-dynamic-table ref="cashBoxesList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @row:clicked="selectedCashBox = $event, showCashBoxProfilePromptStatus = true"
                             @load:more="getCashBoxes()"/>

    <!--  show cashBox details prompt  -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showCashBoxProfilePromptStatus"
      @close="showCashBoxProfilePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div v-if="checkUserPermissions('cash.update')" @click="editCashBoxPromptStatus = true">
              <custom-icon color="warning"
                           icon="EDIT"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.cashBoxes.cashBox.dynamicTitle', {cashBox: selectedCashBox.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showCashBoxProfilePromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <cash-box-menu :cash-box-id="selectedCashBox.id"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <!--  edit bank prompt  -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editCashBoxPromptStatus"
      @close="editCashBoxPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('editCashBoxBTN')">
              <custom-icon color="success"
                           icon="SAVE"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.cashBoxes.edit.dynamicTitle', {cashBox: selectedCashBox.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editCashBoxPromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <edit-cash-box :cash-box-id="selectedCashBox.id" @edited="handleReloadBankList"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!--  insert bank prompt  -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertCashBoxPromptStatus"
      @close="insertCashBoxPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertCashBoxBTN')">
              <custom-icon color="success"
                           icon="SAVE"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.cashBoxes.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertCashBoxPromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <insert-cash-box :in-modal="true" @inserted="handleReloadBankList"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button v-show="false"
            id="insertCashBox"
            @click="insertCashBoxPromptStatus = true"/>
  </div>
</template>

<script>
import {getCashBoxes, getCashBoxesPrice} from '@/http/requests/cashBoxes'
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {addComma, checkUserPermissions} from "../../../../../assets/js/functions";
import CustomIcon from "../../../../../components/customIcon/customIcon";
import CashBoxMenu from "../cashBox/menu/cashBoxMenu";
import EditCashBox from "../cashBox/edit/editCashBox";
import InsertCashBox from "../insert/insertCashBox";

export default {
  name: 'cashBoxList',
  components: {InsertCashBox, EditCashBox, CashBoxMenu, CustomIcon, DraggableDynamicTable},
  metaInfo () {
    return {
      title: this.$t('treasury.cashBoxes.title')
    }
  },
  data () {
    return {
      requestSent: false,
      showCashBoxProfilePromptStatus: false,
      insertCashBoxPromptStatus: false,
      editCashBoxPromptStatus: false,
      selectedCashBox: {},
      loadingTimer: 0,
      options: {
        id: 'cashBoxesList',
        rowKeyField: 'rowNumber'
      },
      columnsLabel: [
        {
          field: 'balance',
          i18n: 'treasury.cashBoxes.table.header.balance',
          width: 'calc(100% / 2)',
          minWidth: 130,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            }
          ],
          footer: {}
        },
        {
          field: 'name',
          i18n: 'treasury.cashBoxes.table.header.name',
          width: 'calc(100% / 2)',
          minWidth: 170,
          align: 'center',
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ],
          locked: true
        },
        {
          field: 'rowNumber',
          i18n: 'treasury.cashBoxes.table.header.row',
          width: '60px',
          minWidth: 60,
          align: 'center',
          // sortable: true,
          locked: true,
          footer: {}
        }
      ],
      data: [],
      page: 1,
      filters: [],
      sorts: [],
      endedList: false,
      priceRequestSent: false,
      actions: [
        {

          toolbar: [
            {
              id: 'insertCashBox',
              icon: 'icon-plus',
              iconPack: 'feather',
              color: 'success',
              permission: 'cash.create'
            }
          ],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            {
              id: {name: 'treasuryCashBoxTrash'},
              type: 'link',
              icon: 'icon-trash',
              iconPack: 'feather',
              permission: 'cash.delete'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    // this.$vs.loading()
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)

    this.getCashBoxesPrice()
    this.getCashBoxes()
  },
  methods: {
    addComma (val) {
      let sign = ''

      if (val < 0) sign = '-'

      val = val.toString()

      if (val[0] === '0') val = val.substr(1, val.length - 1)

      return val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + sign
    },
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    handleClick (id) {
      document.getElementById(id).click()
    },
    getCashBoxes () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.cashBoxesList && this.data.length === 0) this.$refs.cashBoxesList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.cashBoxesList && this.data.length > 0) this.$refs.cashBoxesList.loadMoreStatus = 'Loading'

            getCashBoxes(this.page, this.filters, this.sorts).then(response => {
              this.endedList = response.data.data.length === 0
              const cashBoxes = response.data.data
              cashBoxes.forEach((bank) => {
                this.data.push({
                  // route: {
                  //   name: 'treasuryCashBox',
                  //   params: {id: bank.id}
                  // },
                  rowNumber: this.data.length + 1,
                  id: bank.id,
                  name: bank.name,
                  balance: {
                    type: 'price',
                    classes: bank.balance && bank.balance < 0 ? 'text-danger' : 'text-success',
                    value: bank.balance ? bank.balance : 0
                  }
                })
              })
              this.page = response.data.pagination.current_page + 1
              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer.value = response.data.pagination.total
              }

              if (this.$refs.cashBoxesList) this.$refs.cashBoxesList.loadMoreStatus = ''

              this.requestSent = false
            }).catch(error => {

              if (this.$refs.cashBoxesList) this.$refs.cashBoxesList.loadMoreStatus = 'Danger'

              this.requestSent = false
            })
          } else {
            this.requestSent = false
          }
        }, 400)
      }
    },
    setFilter (filters, remove) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

          case 'name':
            if (filters[key].search !== '') filters_list.push(`name=${  filters[key].search}`)
            break

          case 'balance':
            if (filters[key].search !== '') filters_list.push(`balance=${  filters[key].search  },${  filters[key].type.id}`)
            break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getCashBoxes()
      this.getCashBoxesPrice()
    },
    setSort (sorts) {
      const sorts_list = []
      Object.keys(sorts).forEach((key) => {
        switch (key) {

          case 'rowNumber':
            sorts_list.push(`order[0]=id,${  sorts[key] ? 'desc' : 'asc'}`)
            break

          case 'name':
            sorts_list.push(`order[0]=name,${  sorts[key] ? 'desc' : 'asc'}`)
            break

          case 'balance':
            sorts_list.push(`order[0]=virtual_balance,${  sorts[key] ? 'desc' : 'asc'}`)
            break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getCashBoxes()
    },
    getCashBoxesPrice () {
      if (!this.priceRequestSent) {
        this.priceRequestSent = true
        getCashBoxesPrice(this.filters).then(response => {
          this.priceRequestSent = false
          const price_index = this.columnsLabel.map((e) => {
            return e.field
          }).indexOf('balance')
          this.columnsLabel[price_index].footer = {
            value: addComma(response.data.data.total)
          }
        })
          .catch(() => {
            this.priceRequestSent = false
          })
      }
    },
    handleReloadBankList () {
      this.insertCashBoxPromptStatus = false
      this.editCashBoxPromptStatus = false
      this.showCashBoxProfilePromptStatus = false
      this.data = []
      this.page = 1
      this.endedList = false
      this.getCashBoxes()
      this.getCashBoxesPrice()
    }
  }
}
</script>

<style scoped>

</style>
